import styled from 'styled-components'

export * from './MenuItem'

export type MenuProps = {
  $open: boolean
}

export const Menu = styled.div.attrs({ role: 'menu' })<MenuProps>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.brandWhite};
  box-shadow: ${(props) => props.theme.bigBoxShadow};
  border-radius: ${(props) => props.theme.baseRadius};
  margin-top: 0.25rem;
  padding: 0.5rem;
  /* margin-bottom: 0.25em; */

  /* display: block; */
  height: auto;
  top: 100%;
  max-width: 100vh;
  min-width: 100%;
  overflow-y: hidden;
  position: absolute;
  z-index: ${(props) => (props.$open ? 10000 : 'inherit')};
  /* padding: 0.5rem 0.25rem 0.75rem; */

  transition: max-height
    ${(props) => (props.$open ? '0.15s ease-out' : '0.25s ease-in')};
  max-height: ${(props) => (props.$open ? '70vh' : 0)};
  opacity: ${(props) => (props.$open ? 1 : 0)};

  & > * {
    /* margin: 0em 0.25em 0; */
    /* padding: 0.5rem 0.75rem; */
    display: block;

    /* &:last-child {
      margin-bottom: 0.25rem;
    } */
  }

  && a {
    color: ${(props) => props.theme.colorBlack};
  }
`
