import IntlMessageFormat from 'intl-messageformat'

const LOCALE = 'ru-RU'

function t(message: string) {
  return new IntlMessageFormat(message, LOCALE).format
}

export let mixed = {
  required: t('Поле «{path}» не должно быть пустым'),
}

export let string = {
  email: t('Поле «{path}» должно быть корректным адресом email'),
  phone: t('Поле «{path}» должно быть корректным номером телефона'),
  vin: t('Поле «{path}» должно быть корректным VIN'),
  min: t(
    'Поле «{path}» должно содержать не менее {min, plural, one{# символа} other{# символов}}'
  ),
  max: t(
    'Поле «{path}» должно содержать не более {max, plural, one{# символа} other{# символов}}'
  ),
}

export let number = {
  multiplicity: t('Поле «{path}» должно быть кратно {multiplicity}'),
}

export default Object.assign(Object.create(null), {
  mixed,
  string,
  number,
  // date,
  // object,
  // array,
  // boolean
})
