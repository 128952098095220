import { useMutation, useQueryClient } from 'react-query'

import { ICartItem } from '../../interfaces'
import { updateCartItem } from '../../api'
import { QUERY_KEY } from '../cart_items.hook'

export function useUpdateCartItem() {
  const queryClient = useQueryClient()

  return useMutation(updateCartItem, {
    onSuccess: (data, _variables) => {
      const state = queryClient
        .getQueryData<ICartItem[]>(QUERY_KEY)
        ?.map((item) =>
          item.id === data.id ? { ...data, offer: item.offer } : item
        )
      queryClient.setQueryData(QUERY_KEY, state)
    },

    // onMutate: async (newCartItem) => {
    //   // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
    //   await queryClient.cancelQueries(QUERY_KEY)

    //   // Snapshot the previous value
    //   const previousCartItems = queryClient.getQueryData<ICartItem[]>(QUERY_KEY)

    //   // Optimistically update to the new value
    //   queryClient.setQueryData<ICartItem[]>(QUERY_KEY, (old) =>
    //     (old ?? []).map((item) =>
    //       item.id === newCartItem.id ? { ...item, ...newCartItem } : item
    //     )
    //   )

    //   // Return a context with the previous and new todo
    //   return { previousCartItems }
    // },
    // If the mutation fails, use the context we returned above
    onError: (_err, _newCartItem, context: any) => {
      // queryClient.setQueryData(QUERY_KEY, context.previousCartItems)
    },
    // Always refetch after error or success:
    // onSettled: (newTodo) => {
    //   queryClient.invalidateQueries(['todos', newTodo.id])
    // },
  })
}
