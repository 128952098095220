import { apiClient } from '@monorepo/core'
import { ICompany } from '@monorepo/company_module'

export async function fetchOfficesList() {
  try {
    const { data } = await apiClient.get<ICompany[]>('/offices')
    return data
  } catch (error) {
    return []
  }
}
