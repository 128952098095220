import { useEffect } from 'react'
import { AxiosError } from 'axios'
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query'

import { useAuthenticatation } from '@monorepo/auth_module'
import { fetchCartItems } from '../api'
import { ICartItem } from '../interfaces'

export const QUERY_KEY = 'cart'

export function useCartItems<TData = ICartItem[]>(
  options?: UseQueryOptions<ICartItem[], AxiosError, TData>
) {
  const { isAuthenticated } = useAuthenticatation()

  const queryClient = useQueryClient()

  useEffect(() => {
    if (!isAuthenticated) {
      queryClient.setQueryData(QUERY_KEY, undefined)
    }
  }, [isAuthenticated, queryClient])

  return useQuery(QUERY_KEY as any, fetchCartItems, {
    // refetchOnWindowFocus: false,
    staleTime: 15 * 1000,
    retry: 1,
    enabled: isAuthenticated,
    ...options,
  })
}
