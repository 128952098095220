import { boolean, number, object, SchemaOf, string } from 'yup'

import { UpdateCartItemDTO } from '../dto/update_cart_item.dto'

export const updateCartItemSchema: SchemaOf<UpdateCartItemDTO> = object().shape(
  {
    id: number().required(),
    isSelected: boolean().required(),
    amount: number().label('Количество').required(),
    price: number().label('Цена').required(),
    reference: string().label('Референс').defined().default(''),
    deliveryTime: number().label('Срок поставки').required(),
  }
)
