import { useCallback, useMemo } from 'react'
import { useLocation, useHistory } from 'react-router'
import toSafeInteger from 'lodash/toSafeInteger'
import qs from 'qs'
import { flow, clamp } from 'lodash/fp'

import { IPagination } from '../../interfaces'

const formatPage = (totalPages?: number) =>
  flow(toSafeInteger, clamp(1, totalPages ?? Infinity))

export function usePagination(meta: IPagination, pageProp = 'page') {
  const location = useLocation()
  const history = useHistory()
  const getPage = useMemo(() => formatPage(meta.totalPages), [meta.totalPages])

  const [currentPage, ...currentQuery] = useMemo(() => {
    const { [pageProp]: queryPage, ...restQuery } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    })
    const page = getPage(queryPage)

    return [page, restQuery]
  }, [getPage, location.search, pageProp])

  const hrefBuilder = useCallback(
    (page) => {
      const nextQuery = qs.stringify(
        {
          ...currentQuery,
          ...(page > 1 && { [pageProp]: page }),
        },
        { addQueryPrefix: true }
      )
      const url = [location.pathname, nextQuery, location.hash].join('')
      return url
    },
    [currentQuery, location.hash, location.pathname, pageProp]
  )

  const gotoUrl = useCallback(
    (url: string) => {
      history.push(url)
    },
    [history]
  )

  const onPageChange = useCallback(
    ({ selected }) => {
      gotoUrl(hrefBuilder(selected + 1))
    },
    [gotoUrl, hrefBuilder]
  )

  return {
    pageCount: meta.totalPages,
    forcePage: getPage(meta.currentPage ?? currentPage) - 1,
    hrefBuilder,
    onPageChange,
  }
}
