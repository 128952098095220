import { apiClient } from '@monorepo/core'

import { IContractOperation, IOperationsFilter } from '../interfaces'

export async function fetchContractOperations({
  contract,
  ...filter
}: IOperationsFilter) {
  try {
    const { data } = await apiClient.get<IContractOperation[]>(
      `/contracts/${contract?.number}/show`,
      { params: filter }
    )

    return data
  } catch (error) {
    return []
  }
}
