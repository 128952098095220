import { NumberSchema, number, addMethod } from 'yup'
import Reference from 'yup/lib/Reference'
import { Message } from 'yup/lib/types'

import { number as messages } from '../locales/ru'

addMethod<NumberSchema>(
  number,
  'multiplicity',
  function (ref: Reference | number, message = messages.multiplicity) {
    return this.test({
      name: 'multiplicity',
      test: function (value) {
        const multiplicity = this.resolve(ref)

        return typeof value !== 'undefined' &&
          typeof multiplicity === 'number' &&
          value % multiplicity !== 0
          ? this.createError({
              message,
              path: this.path,
              params: {
                multiplicity,
              },
            })
          : true
      },
    })
  }
)

declare module 'yup' {
  interface NumberSchema {
    multiplicity(
      value: Reference | number,
      message?: string | ((params: Message<{ multiplicity: number }>) => string)
    ): NumberSchema
  }
}
