import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'

import { IVendor } from '@monorepo/vendor_module'
import { fetchApiVendors } from '../api'

const QUERY_KEY = 'remote-vendors'

export function useRemoteApi<TData = IVendor[]>(
  options?: UseQueryOptions<IVendor[], AxiosError, TData>
) {
  return useQuery(QUERY_KEY as any, fetchApiVendors, {
    refetchOnWindowFocus: false,
    staleTime: 30 * 60 * 1000,
    ...options,
  })
}
