import axios from 'axios'

import { apiClient } from '@monorepo/core'
import { IGood } from '@monorepo/application_module'

type FetchByQuery = {
  brandName: string
  number: string
  crosses: boolean
  // stockOnly = undefined
  vendor?: number
  // survey = false
}

export async function fetchOffersBy(params: FetchByQuery) {
  const source = axios.CancelToken.source()

  const promise: Promise<IGood[]> & {
    cancel?(): void
  } = apiClient
    .get(`/v2/search/findByQuery`, {
      cancelToken: source.token,
      params,
    })
    .then((result) => result.data)
    .catch(() => [])

  promise.cancel = source.cancel.bind(
    null,
    'Query was cancelled by React Query'
  )

  return promise
}
