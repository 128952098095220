import { StringSchema, string, addMethod } from 'yup'
import { Message } from 'yup/lib/types'

import { string as messages } from '../locales/ru'

const re = /^[A-HJ-NPR-Z\d]{13}\d{4}$/i

addMethod<StringSchema>(string, 'vin', function (message = messages.vin) {
  return this.test({
    name: 'vin',
    exclusive: true,
    message,
    test: (value) => {
      return this.length(17).matches(re).isValid(value)
    },
  })
})

declare module 'yup' {
  interface StringSchema {
    vin(message?: string | ((params: Message) => string)): StringSchema
  }
}
