import React from 'react'
import cn from 'classnames'

import { useMenu } from '@monorepo/menu_module'
import { useComponentVisible } from '@monorepo/application_module/hooks'
import { Menu } from './Menu/Menu'

// import style from './style.module.scss'

type MenuButtonProps = {
  logoImage: string
}

export const MenuButton: React.FC<MenuButtonProps> = ({ logoImage }) => {
  const { data: menu } = useMenu('main')

  const { isVisible, hide, toggle } = useComponentVisible()

  return (
    <>
      <div
        onClick={toggle}
        className="mobile-container-toggle hbe hbe-icon-element hide-default-false hide-sticky-false hbe-left size-medium"
      >
        <div
          className="mobile-toggle hbe-toggle ien-emenu-2"
          data-close-icon="ien-eclose-3"
        />
      </div>
      <div
        className={cn('mobile-container-overlay', {
          active: isVisible,
        })}
      />
      {menu && <Menu items={menu} logoImage={logoImage} onHide={hide} />}
    </>
  )
}
