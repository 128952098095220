import axios from 'axios'
import {
  AddressSuggestions,
  DaDataAddress,
  DaDataSuggestion,
} from 'react-dadata'

import 'react-dadata/dist/react-dadata.css'

import { token } from '../config'

const addressSuggestions = new AddressSuggestions({
  token,
})

export async function fetchAddressSuggestions(query: string) {
  try {
    const suggestionsData = addressSuggestions.getLoadSuggestionsData()

    const payload = {
      ...(typeof suggestionsData === 'object' ? suggestionsData : {}),
      token: addressSuggestions.props.token,
      query,
    }

    const { data } = await axios.get<{
      suggestions: DaDataSuggestion<DaDataAddress>[]
    }>(addressSuggestions.loadSuggestionsUrl, {
      params: payload,
    })

    return data.suggestions
  } catch (error) {
    return []
  }
}
