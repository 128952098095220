import { apiClient } from '@monorepo/core'
import { FormError } from '@monorepo/application_module/components/form_error'

import { CartItemId } from '../interfaces'

export async function removeCartItem(id: CartItemId) {
  try {
    const { data } = await apiClient.get(`/cart/remove/${id}`)

    return data
  } catch (error) {
    throw FormError.create(error, 'Ошибка при удалении')
  }
}
