import styled from 'styled-components'

export const ScreenReaderText = styled.label`
  & {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
  }
`
