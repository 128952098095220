import React, { useState, useCallback, useMemo } from 'react'
// import { filter, orderBy, partition, sortBy } from 'lodash/fp'

import { IOffer } from '@monorepo/application_module'
import { getBestOffers, sortOffersBy, sortOrders } from './utils'

type RenderControlProps = {
  items: IOffer[]
  isExpanded: boolean
  expand(): void
  collapse(): void
  toggle(): void
  totalItems: number
}

type OffersControlProps = {
  items: IOffer[]
  render?(props: { item: IOffer }): React.ReactElement
  renderControl?(props: RenderControlProps): React.ReactNode
  children?: (params: { items: IOffer[] }) => React.ReactNode
}

export const OffersControl: React.FC<OffersControlProps> = ({
  children,
  renderControl,
  items: allItems,
  render,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const expand = useCallback(() => {
    setIsExpanded(true)
  }, [])
  const collapse = useCallback(() => {
    setIsExpanded(false)
  }, [])
  const toggle = useCallback(() => {
    setIsExpanded((prev) => !prev)
  }, [])

  const sortProp = sortOrders.deliveryTime

  const sortOffers = useMemo(() => sortOffersBy(sortProp), [sortProp])

  const items = sortOffers(isExpanded ? allItems : getBestOffers(allItems))

  return (
    <>
      {render &&
        items.map((item, idx) =>
          React.cloneElement(render({ item }), { key: idx })
        )}
      {children?.({ items })}
      {renderControl?.({
        items,
        isExpanded,
        expand,
        collapse,
        toggle,
        totalItems: allItems.length,
      })}
    </>
  )
}
