import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartLine } from '@fortawesome/free-solid-svg-icons'

import { IOffer, useComponentVisible } from '@monorepo/application_module'

import { VendorDeliveryModal } from './vendor_delivery_modal'
import { IconButton } from '../icon_button'

type DeliveryInfoProps = {
  item: IOffer
}

export const VendorDeliveryInfo: React.FC<DeliveryInfoProps> = ({ item }) => {
  const { isVisible, show, hide } = useComponentVisible()

  if (item.isStock) {
    return null
  }

  return (
    <>
      <OverlayTrigger
        placement="auto"
        delay={50}
        overlay={
          <Popover id="vds-popover">
            <Popover.Content>
              {item.vendor.name
                ? `Открыть статистику поставок «${item.vendor.name}»`
                : 'Открыть статистику поставок'}
            </Popover.Content>
          </Popover>
        }
      >
        <IconButton onClick={show}>
          <FontAwesomeIcon icon={faChartLine} />
        </IconButton>
      </OverlayTrigger>

      <VendorDeliveryModal item={item} show={isVisible} onHide={hide} />
    </>
  )
}
