import styled from 'styled-components'

import { Container } from './container'

export const SubMenu = styled.div<{ $right: boolean }>`
  background-color: ${(props) => props.theme.brandWhite};
  box-shadow: ${(props) => props.theme.baseBoxShadow};

  position: absolute;
  top: calc(100% - 1px);
  min-width: 256px;
  max-width: 256px;
  list-style: none;
  // margin-left: 0;
  margin-top: 2px;
  text-align: left;
  border-radius: ${(props) =>
    `0 0 ${props.theme.baseRadius} ${props.theme.baseRadius}`} !important;

  right: ${(props) => (props.$right ? '0' : 'auto')} !important;
  left: ${(props) => (props.$right ? 'auto' : '0')} !important;
  transform: translateY(-10%);
  visibility: hidden;
  opacity: 0;

  ${Container}:hover & {
    transition: opacity 150ms 300ms ease-out,
      transform 600ms 300ms cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
    z-index: 2;
    height: auto;
    // display: block;
  }
`
