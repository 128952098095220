import { Page as BasePage } from './Page'
import { PageTitle } from './PageTitle'
import { Section } from './PageSection'

export * from './Page'
export * from './PageTitle'
export * from './PageSection'
export * from './breadcrumbs'

export const Page = Object.assign(BasePage, { Title: PageTitle, Section })
