import React, { useMemo } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Modal, { ModalProps } from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'

import { IOffer } from '@monorepo/application_module'
import { useStatistics } from '@monorepo/vendor_module'

import { CompletedPercentage } from './blocks/completed_percentage'
import { IssuesQuality } from './blocks/issues_quality'
import { TimeStatisticsTable } from './blocks/time_statistics_table'
import {
  isDeliveryStatisticsEmpty,
  isTimeTableEmpty,
  isTimeStatisticsEmpty,
} from './utils'
import { CompositButton } from 'App/Components'
import { Callout } from 'App/Components/Callout'

type DeliveryInfoProps = ModalProps & {
  item: IOffer
}

export const VendorDeliveryModal: React.FC<DeliveryInfoProps> = ({
  item,
  ...props
}) => {
  const { data: statistics, isFetched } = useStatistics(item.vendor, {
    enabled: props.show,
  })

  const isStatisticsEmpty = useMemo(() => {
    return (
      isDeliveryStatisticsEmpty(statistics) &&
      isTimeTableEmpty(statistics) &&
      isTimeStatisticsEmpty(statistics)
    )
  }, [statistics])

  if (item.isStock) {
    return null
  }

  return (
    <Modal {...props}>
      <Modal.Header closeButton>
        <Modal.Title>
          {item.vendor.name
            ? `Статистика поставок «${item.vendor.name}»`
            : 'Статистика поставок'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isFetched ? (
          isStatisticsEmpty ? (
            <Callout variant="danger">
              {item.vendor.name
                ? `Статистики поставок «${item.vendor.name}» пока нет`
                : 'Статистики поставок пока нет'}
            </Callout>
          ) : (
            <Row>
              {statistics && !isDeliveryStatisticsEmpty(statistics) && (
                <Col xs={12} md={6}>
                  <CompletedPercentage
                    width={200}
                    height={200}
                    data={statistics.deliveryStatistics}
                  />
                </Col>
              )}
              {statistics && !isTimeTableEmpty(statistics) && (
                <Col xs={12} md={6}>
                  <IssuesQuality
                    width={200}
                    height={200}
                    data={statistics?.timeTable}
                  />
                </Col>
              )}
              {statistics && !isTimeStatisticsEmpty(statistics) && (
                <Col xs={12} className="mt-4">
                  <TimeStatisticsTable data={statistics.timeStatistics} />
                </Col>
              )}
            </Row>
          )
        ) : (
          <>
            <Spinner animation="border" variant="primary" /> Загрузка данных...
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Col xs={12} className="text-center">
          <CompositButton onClick={props.onHide} outline>
            Закрыть
          </CompositButton>
        </Col>
      </Modal.Footer>
    </Modal>
  )
}
