import React from 'react'

import { IGood } from '@monorepo/application_module'
import { getSearchUrl } from '@monorepo/application_module/helpers'
import { SuggestionItem } from './SuggestionItem'

type SuggestionsListProps = React.HTMLAttributes<HTMLDivElement> & {
  activeItemIdx: number
  iconSize: string | number
  suggestions: IGood[]
  handleSelect?(suggestion: IGood): void
}

export const SuggestionsList: React.FC<SuggestionsListProps> = ({
  activeItemIdx,
  iconSize = '50px',
  suggestions,
  handleSelect,
  ...props
}) => (
  <div {...props}>
    {suggestions.map((item, idx) => (
      <SuggestionItem
        key={item.id}
        brandName={item.brand.name}
        article={item.number}
        description={item.name}
        image={item.thumbs?.[0]}
        imageSize={iconSize}
        href={getSearchUrl(item)}
        onSelect={handleSelect?.bind(null, item)}
        active={idx === activeItemIdx}
      />
    ))}
  </div>
)
