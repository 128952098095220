import React from 'react'
import {
  FormikContextType,
  FormikHelpers,
  FormikProvider,
  useFormik,
  useFormikContext,
} from 'formik'
import defer from 'lodash/defer'

import { FormError } from '@monorepo/application_module/components/form_error'
import { useAuthenticatation } from '@monorepo/auth_module'
import { ResetPasswordDTO } from '../dto'
import { resetPasswordSchema } from '../schema'
import { useHistory } from 'react-router'

type ResetPasswordProviderProps = {
  token: string
  redirect?: string
  onSuccess?(): void | Promise<void>
  onError?(error: FormError): void
  children:
    | ((props: FormikContextType<ResetPasswordDTO>) => React.ReactNode)
    | React.ReactNode
}

export const ResetPasswordProvider: React.FC<ResetPasswordProviderProps> = ({
  token,
  redirect = '/',
  children,
  onSuccess,
  onError,
}) => {
  const history = useHistory()

  const { resetPassword } = useAuthenticatation()

  const initialValues: ResetPasswordDTO = {
    token,
    plainPassword: {
      first: '',
      second: '',
    },
  }

  const onSubmit = async (
    values: ResetPasswordDTO,
    helpers: FormikHelpers<ResetPasswordDTO>
  ) => {
    const dto = resetPasswordSchema.cast(values, {
      stripUnknown: true,
    }) as ResetPasswordDTO

    try {
      await resetPassword(dto)

      await onSuccess?.()
      helpers.setSubmitting(false)
      redirect && defer(history.replace, redirect)
    } catch (error) {
      helpers.setSubmitting(false)
      onError?.(
        FormError.isFormError(error)
          ? error
          : new FormError('Ошибка при восстановлении')
      )
    }
  }

  const formik: FormikContextType<ResetPasswordDTO> =
    useFormik<ResetPasswordDTO>({
      initialValues,
      onSubmit,
      enableReinitialize: true,
      validationSchema: resetPasswordSchema,
    })

  return (
    <FormikProvider value={formik}>
      {typeof children === 'function'
        ? (
            children as (
              props: FormikContextType<ResetPasswordDTO>
            ) => React.ReactNode
          )(formik)
        : children}
    </FormikProvider>
  )
}

export function useResetPasswordForm() {
  return useFormikContext<ResetPasswordDTO>()
}
