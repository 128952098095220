import { useCallback } from 'react'
import { useQueryClient } from 'react-query'

import { IUser } from '../interfaces'
import { QUERY_KEY } from './constants'

export function useClearCurrentUser() {
  const queryClient = useQueryClient()

  const clearUser = useCallback(() => {
    queryClient.setQueryData<IUser | undefined>(QUERY_KEY, undefined)
  }, [queryClient])

  return { clearUser }
}
