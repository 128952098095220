import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'
import { orderBy } from 'lodash/fp'

import { IGood } from '@monorepo/application_module'
import { fetchSuggestions } from '../api'

type RequestOptionsType = {
  strict?: boolean
  useFTS?: boolean
}

const QUERY_KEY = 'good-suggestions'

const orderGoods = orderBy<IGood>(
  ['suggestionRating', 'brand.name'],
  ['desc', 'asc']
)

type SuggestionsFilter = {
  query: string
  strict: boolean
  useFTS: boolean
}

export function useSuggestions<TData = IGood[]>(
  { query, ...filter }: SuggestionsFilter,
  options?: UseQueryOptions<IGood[], AxiosError, TData> & RequestOptionsType
) {
  return useQuery(
    [QUERY_KEY, query, filter] as any,
    () => fetchSuggestions(query ?? '', filter),
    {
      refetchOnWindowFocus: false,
      select: (data) => orderGoods(data) as unknown as TData,
      staleTime: 15 * 60 * 1000,
      ...options,
      enabled: (options?.enabled ?? true) && query?.length > 0,
    }
  )
}
