enum Tokens {
  token = 'token',
  refreshToken = 'refresh_token',
}

export function setTokens(token: string, refresh_token: string) {
  try {
    localStorage.setItem(Tokens.token, token)
    localStorage.setItem(Tokens.refreshToken, refresh_token)
  } catch (error) {}
}

export function clearTokens() {
  try {
    localStorage.removeItem(Tokens.token)
    localStorage.removeItem(Tokens.refreshToken)
  } catch (error) {}
}

export function getToken() {
  try {
    return localStorage.getItem(Tokens.token) || undefined
  } catch (error) {
    return undefined
  }
}

export function getRefreshToken() {
  try {
    return localStorage.getItem(Tokens.refreshToken) || undefined
  } catch (error) {
    return undefined
  }
}
