import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type IconButtonProps = {
  $size?: number | string
  variant?: 'danger' | 'warning'
}

export const IconButton = styled.button.attrs<IconButtonProps>((props) => ({
  $size: props.$size ?? 28,
}))<IconButtonProps>`
  background-color: ${(props) =>
    (props.variant === 'danger' && props.theme.danger) ||
    (props.variant === 'warning' && props.theme.warning) ||
    props.theme.primary};
  color: ${(props) => props.theme.colorWhite};
  border: 0;
  margin: 2px;
  border-radius: ${(props) => props.theme.baseRadius};
  padding: 4px 2px;
  height: ${(props) => parseInt(`${props.$size}`)}px;
  width: ${(props) => parseInt(`${props.$size}`)}px;
  /* box-shadow: ${(props) => props.theme.baseBoxShadow}; */
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Icon = styled(FontAwesomeIcon).attrs({ fixedWidth: true })`
  /*  */
`
