import React from 'react'
import {
  FormikContextType,
  FormikHelpers,
  FormikProvider,
  useFormik,
  useFormikContext,
} from 'formik'

import { FormError } from '@monorepo/application_module/components/form_error'
import { useAuthenticatation } from '@monorepo/auth_module'
import { ForgotPasswordDTO } from '../dto'
import { forgotPasswordSchema } from '../schema'

type ForgotPasswordProviderProps = {
  onSuccess?(): void | Promise<void>
  onError?(error: FormError): void
  children:
    | ((props: FormikContextType<ForgotPasswordDTO>) => React.ReactNode)
    | React.ReactNode
}

export const ForgotPasswordProvider: React.FC<ForgotPasswordProviderProps> = ({
  children,
  onSuccess,
  onError,
}) => {
  const { forgotPassword } = useAuthenticatation()

  const initialValues: ForgotPasswordDTO = {
    email: '',
  }

  const onSubmit = async (
    values: ForgotPasswordDTO,
    helpers: FormikHelpers<ForgotPasswordDTO>
  ) => {
    const dto = forgotPasswordSchema.cast(values, {
      stripUnknown: true,
    }) as ForgotPasswordDTO

    try {
      await forgotPassword(dto)

      await onSuccess?.()
      helpers.setSubmitting(false)
    } catch (error) {
      helpers.setSubmitting(false)
      onError?.(
        FormError.isFormError(error)
          ? error
          : new FormError('Ошибка при восстановлении')
      )
    }
  }

  const formik: FormikContextType<ForgotPasswordDTO> =
    useFormik<ForgotPasswordDTO>({
      initialValues,
      onSubmit,
      enableReinitialize: true,
      validationSchema: forgotPasswordSchema,
    })

  return (
    <FormikProvider value={formik}>
      {typeof children === 'function'
        ? (
            children as (
              props: FormikContextType<ForgotPasswordDTO>
            ) => React.ReactNode
          )(formik)
        : children}
    </FormikProvider>
  )
}

export function useForgotPasswordForm() {
  return useFormikContext<ForgotPasswordDTO>()
}
