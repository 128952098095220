import { object, string, SchemaOf } from 'yup'

import { IAddressSuggestion } from '../interfaces'

export const addressSuggestionShema: SchemaOf<IAddressSuggestion> = object({
  unrestricted_value: string().default(''),
  value: string().default(''),
  data: object({
    //   area: string().default(null),
    //   area_fias_id: string().default(null),
    //   area_kladr_id: string().default(null),
    //   area_type: string().default(null),
    //   area_type_full: string().default(null),
    //   area_with_type: string().default(null),
    //   beltway_distance: number().nullable(),
    //   beltway_hit: mixed().nullable(),
    //   block: string().nullable().default(undefined),
    //   block_type: string().nullable().default(undefined),
    //   block_type_full: string().nullable().default(undefined),
    //   capital_marker: string().nullable().default(undefined),
    city: string().nullable().default(undefined),
    //   city_area: string().nullable().default(undefined),
    //   city_district: string().nullable().default(undefined),
    //   city_district_fias_id: string().nullable().default(undefined),
    //   city_district_kladr_id: string().nullable().default(undefined),
    //   city_district_type: string().nullable().default(undefined),
    //   city_district_type_full: string().nullable().default(undefined),
    //   city_district_with_type: string().nullable().default(undefined),
    //   city_fias_id: string().nullable().default(undefined),
    //   city_kladr_id: string().nullable().default(undefined),
    //   city_type: string().nullable().default(undefined),
    //   city_type_full: string().nullable().default(undefined),
    //   city_with_type: string().nullable().default(undefined),
    //   country: string().nullable().default(undefined),
    //   country_iso_code: string().nullable().default(undefined),
    //   entrance: string().nullable().default(undefined),
    //   federal_district: string().nullable().default(undefined),
    //   fias_actuality_state: string().nullable().default(undefined),
    //   fias_code: string().nullable().default(undefined),
    //   fias_id: string().nullable().default(undefined),
    //   fias_level: string().nullable().default(undefined),
    //   flat: string().nullable().default(undefined),
    //   flat_area: string().nullable().default(undefined),
    //   flat_cadnum: string().nullable().default(undefined),
    //   flat_fias_id: string().nullable().default(undefined),
    //   flat_price: string().nullable().default(undefined),
    //   flat_type: string().nullable().default(undefined),
    //   flat_type_full: string().nullable().default(undefined),
    //   floor: string().nullable().default(undefined),
    //   geo_lat: string().nullable().default(undefined),
    //   geo_lon: string().nullable().default(undefined),
    //   geoname_id: string().nullable().default(undefined),
    //   history_values: string().nullable().default(undefined),
    //   house: string().nullable().default(undefined),
    //   house_cadnum: string().nullable().default(undefined),
    //   house_fias_id: string().nullable().default(undefined),
    //   house_kladr_id: string().nullable().default(undefined),
    //   house_type: string().nullable().default(undefined),
    //   house_type_full: string().nullable().default(undefined),
    //   kladr_id: string().nullable().default(undefined),
    //   metro: string().nullable().default(undefined),
    //   okato: string().nullable().default(undefined),
    //   oktmo: string().nullable().default(undefined),
    //   postal_box: string().nullable().default(undefined),
    //   postal_code: string().nullable().default(undefined),
    //   qc: string().nullable().default(undefined),
    //   qc_complete: string().nullable().default(undefined),
    //   qc_geo: string().nullable().default(undefined),
    //   qc_house: string().nullable().default(undefined),
    //   region: string().nullable().default(undefined),
    //   region_fias_id: string().nullable().default(undefined),
    //   region_iso_code: string().nullable().default(undefined),
    //   region_kladr_id: string().nullable().default(undefined),
    //   region_type: string().nullable().default(undefined),
    //   region_type_full: string().nullable().default(undefined),
    //   region_with_type: string().nullable().default(undefined),
    //   settlement: string().nullable().default(undefined),
    //   settlement_fias_id: string().nullable().default(undefined),
    //   settlement_kladr_id: string().nullable().default(undefined),
    //   settlement_type: string().nullable().default(undefined),
    //   settlement_type_full: string().nullable().default(undefined),
    //   settlement_with_type: string().nullable().default(undefined),
    //   source: string().nullable().default(undefined),
    //   square_meter_price: string().nullable().default(undefined),
    //   street: string().nullable().default(undefined),
    //   street_fias_id: string().nullable().default(undefined),
    //   street_kladr_id: string().nullable().default(undefined),
    //   street_type: string().nullable().default(undefined),
    //   street_type_full: string().nullable().default(undefined),
    //   street_with_type: string().nullable().default(undefined),
    //   tax_office: string().nullable().default(undefined),
    //   tax_office_legal: string().nullable().default(undefined),
    //   timezone: string().nullable().default(undefined),
    //   unparsed_parts: string().nullable().default(undefined),
  })
    .nullable()
    .default(undefined),
})
