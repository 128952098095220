import styled from 'styled-components'

import Base from './user_menu'

export * from './user_menu'

export const UserMenu = styled(Base)`
  &&& {
    button {
      background-color: transparent;
    }

    .dropdown-menu {
      a {
        color: ${(props) => props.theme.colorLightBlack};

        &:hover {
          color: ${(props) => props.theme.colorWhite};
        }
      }
    }
  }
`
