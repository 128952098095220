import React, { useMemo } from 'react'
import {
  FormikContextType,
  FormikProps,
  FormikProvider,
  useFormik,
} from 'formik'

import { ICartItem } from '../interfaces'
import { UpdateCartItemDTO } from '../dto'
import { useUpdateCartItem } from '../hooks/crud'
import { updateCartItemSchema } from '../schema'

type UpdateCartItemProviderProps = Partial<
  FormikContextType<UpdateCartItemDTO>
> & {
  item: ICartItem
  children:
    | ((props: FormikProps<UpdateCartItemDTO>) => React.ReactNode)
    | React.ReactNode
}

export const UpdateCartItemProvider: React.FC<UpdateCartItemProviderProps> = ({
  item,
  children,
  ...props
}) => {
  const initialValues: UpdateCartItemDTO = useMemo(
    () => ({
      id: item.id,
      isSelected: item.isSelected,
      amount: item.amount ?? 0,
      price: item.price,
      deliveryTime: item.deliveryTime,
      reference: item.reference,
    }),
    [item]
  )

  const { mutateAsync } = useUpdateCartItem()

  const onSubmit = (values: UpdateCartItemDTO) => {
    mutateAsync(values)
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: updateCartItemSchema,
    ...props,
  })

  return (
    <FormikProvider value={formik}>
      {typeof children === 'function' ? children(formik) : children}
    </FormikProvider>
  )
}
