import axios from 'axios'

import { IFieldError } from './interfaces'

export class FormError extends Error {
  readonly errors: IFieldError[]
  readonly isFormError = true

  static isFormError(payload: any): payload is FormError {
    return payload.isFormError === true
  }

  static create(error: any, message: string = error.message) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 401) {
        return new FormError(message, 'Вы не авторизованы')
      }

      return new FormError(
        message,
        error.response?.data?.errors ?? error.response?.data?.result?.errors
      )
    } else {
      return new FormError(message)
    }
  }

  constructor(
    message: string = 'Ошибка',
    errors: string | IFieldError | IFieldError[] = 'Непредвиденная ошибка'
  ) {
    super(message)
    this.errors =
      typeof errors === 'string'
        ? [{ message: errors } as IFieldError]
        : Array.isArray(errors)
        ? errors
        : [errors]
  }
}
