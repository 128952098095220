import { apiClient } from '@monorepo/core'

import { IOperationTransaction, ITransactionsFilter } from '../interfaces'

export async function fetchOperationTransaction({
  operationId,
  ...filter
}: ITransactionsFilter) {
  try {
    const { data } = await apiClient.get<IOperationTransaction>(
      `/contracts/transactions/${operationId}`,
      { params: filter }
    )

    return data
  } catch (error) {
    throw error
  }
}
