import { apiClient } from '@monorepo/core'
import { FormError } from '@monorepo/application_module/components/form_error'

import { IUser } from '../interfaces'

export async function fetchUser() {
  try {
    const { data } = await apiClient.get<IUser>('/user')

    return data
  } catch (error) {
    throw FormError.create(error)
  }
}
