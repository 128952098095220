import { IModule } from '@monorepo/application_module'
import { UserProvider as Provider } from './user_provider'

export * from './hooks'
export * from './interfaces'
export * from './components'

export default {
  Provider,
} as IModule
