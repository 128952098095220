import { useMutation, useQueryClient } from 'react-query'

import { ICartItem } from '../../interfaces'
import { createCartItem } from '../../api'
import { QUERY_KEY } from '../cart_items.hook'

export function useCreateCartItem() {
  const queryClient = useQueryClient()

  return useMutation(createCartItem, {
    onSuccess: async (newCartItem: ICartItem) => {
      const previousCartItems = queryClient.getQueryData<ICartItem[]>(QUERY_KEY)

      queryClient.setQueryData<ICartItem[]>(QUERY_KEY, (prev = []) => {
        const next = [...prev]
        const currentIdx = next.findIndex(
          (cartItem) => cartItem.id === newCartItem.id
        )
        currentIdx === -1
          ? next.unshift(newCartItem)
          : next.splice(currentIdx, 1, newCartItem)

        return next
      })

      return { previousCartItems }
    },
    onError: (_err, _newCartItem, context: any) => {
      // queryClient.setQueryData(QUERY_KEY, context.previousCartItems)
    },
  })
}
