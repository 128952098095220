import styled from 'styled-components'

import type { ButtonOptions } from '../types'

export const BaseBlock = styled.span<ButtonOptions>`
  /* border-radius: inherit; */
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 200ms linear;
  width: 100%;
  opacity: 1;
`
