import { apiClient } from '@monorepo/core'
import { FormError } from '@monorepo/application_module/components/form_error'

import { UpdateCartItemDTO } from '../dto/update_cart_item.dto'
import { ICartItem } from '../interfaces'

export async function updateCartItem(dto: UpdateCartItemDTO) {
  try {
    const { id, ...values } = dto

    const { data } = await apiClient.post<ICartItem>(
      `/cart/update/${id}`,
      values
    )
    return data
  } catch (error) {
    throw FormError.create(error, 'Ошибка при изменении')
  }
}
