import { apiClient } from '@monorepo/core'
import { FormError } from '@monorepo/application_module/components/form_error'

import { RegistrationDTO } from '../dto'

export async function register(dto: RegistrationDTO) {
  try {
    const { data } = await apiClient.post('/user/registration', dto)

    return data
  } catch (error) {
    throw FormError.create(error, 'Ошибка при регистрации')
  }
}
