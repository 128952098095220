import React from 'react'

import { ThemeVariant } from './enums'

export interface IThemeContext {
  themeVariant: ThemeVariant
  isDark: boolean
  setThemeVariant(theme: ThemeVariant): void
  nextThemeVariant(): void
}

export const ThemeContext = React.createContext<IThemeContext | null>(null)
