import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosError } from 'axios'

import { useAuthenticatation } from '@monorepo/auth_module'
import { IUser } from '../interfaces'
import { fetchUser } from '../api'
import { QUERY_KEY } from './constants'

export function useCurrentUser<TData = IUser>(
  options?: UseQueryOptions<IUser, AxiosError, TData>
) {
  const { isAuthenticated } = useAuthenticatation()

  return useQuery(QUERY_KEY as any, fetchUser, {
    enabled: isAuthenticated,
    staleTime: 5 * 60 * 1000,
    ...options,
  })
}
