import React from 'react'
import { Doughnut } from 'react-chartjs-2'

import { IDeliveryStatistics } from '@monorepo/vendor_module'

type CompletedPercentageProps = {
  width: number
  height: number
  data: IDeliveryStatistics['deliveryStatistics']
}

const options = {
  responsive: true,
  title: {
    display: true,
    text: 'Качество выдачи данным поставщиком',
  },
}

export const CompletedPercentage: React.FC<CompletedPercentageProps> = ({
  width,
  height,
  data: statistics,
}) => {
  const { denied, delivered } = statistics

  const data = {
    labels: ['отказано', 'выдано'],
    datasets: [
      {
        data: [denied, delivered],
        backgroundColor: [
          'rgba(225, 87, 41, 0.75)',
          'rgba(139, 209, 97, 0.75)',
        ],
        borderColor: ['rgba(225, 87, 41, 1)', 'rgba(139, 209, 97, 1)'],
        datalabels: {
          formatter: (value: number) => (value > 5 ? `${value}%` : ''),
        },
      },
    ],
  }

  return (
    <Doughnut data={data} options={options} width={width} height={height} />
  )
}
