import React from 'react'
import { useField } from 'formik'
import { AddressSuggestions, DaDataAddress } from 'react-dadata'
import { CommonProps, DaDataSuggestion } from 'react-dadata/dist/types'

import { useAddressSuggestions } from '../hooks'
import { token } from '../config'
// import { IDeliveryAddress } from '../../../../../../modules/cart_module/interfaces'

type AddressFieldProps = CommonProps<DaDataAddress> & {
  //
}

export const AddressField: React.FC<AddressFieldProps> = ({
  ...props
}: any) => {
  // const [field, , helpers] = useField<IDeliveryAddress | undefined>(props.name)
  const [field, , helpers] = useField<any>(props.name)
  const { data: suggestion } = useAddressSuggestions(field.value?.value, {
    select: (suggestions) =>
      suggestions.find(
        (suggestion) =>
          suggestion.value?.toLowerCase() === props.value.value?.toLowerCase()
      ),
    enabled: false,
  })

  const inputValue =
    typeof field.value === 'string' ? field.value : field.value?.value

  return (
    <AddressSuggestions
      value={
        suggestion ?? ({ value: inputValue } as DaDataSuggestion<DaDataAddress>)
      }
      // defaultQuery={suggestion?.value ?? inputValue}
      token={token}
      onChange={(value) => {
        console.log(value, typeof value === 'string' ? { value } : value)
        helpers.setValue(typeof value === 'string' ? { value } : value)
      }}
      minChars={3}
      delay={500}
      inputProps={props}
    />
  )
}
