import React from 'react'

import { IMenu } from '@monorepo/menu_module'
import { MenuItem } from './MenuItem'

type MenuItemsListProps = React.HTMLAttributes<HTMLUListElement> & {
  items: IMenu
}

export const MenuItemsList: React.FC<MenuItemsListProps> = ({
  items,
  ...props
}) => (
  <ul {...props}>
    {items.map((item, idx) => (
      <MenuItem key={idx} item={item} />
    ))}
  </ul>
)
