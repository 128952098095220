import React from 'react'

import { IOffer } from '@monorepo/application_module'

export interface IAddToCartContext {
  isAdding: boolean
  addToCart(offer: IOffer): void
  offer: IOffer | undefined
  close(): void
}

export const AddToCartContext = React.createContext<IAddToCartContext | null>(
  null
)
