import React from 'react'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { faHome } from '@fortawesome/free-solid-svg-icons'

import { IOffer } from '@monorepo/application_module'
import { useCurrentUser } from '@monorepo/user_module'
import { Icon, IconButton } from './icon_button'

type OfficeInfoProps = {
  item: IOffer
}

export const OfficeInfo: React.FC<OfficeInfoProps> = ({ item }) => {
  const { data: userOffice } = useCurrentUser({ select: (user) => user.office })

  const isUserOffice = userOffice && userOffice.id === item.vendor.stock?.id

  if (!isUserOffice) {
    return null
  }

  return (
    <OverlayTrigger
      placement="auto"
      overlay={
        <Popover id="home-office-popover">
          <Popover.Content>
            Товар находится в вашем офисе обслуживания
          </Popover.Content>
        </Popover>
      }
    >
      <IconButton>
        <Icon icon={faHome} />
      </IconButton>
    </OverlayTrigger>
  )
}
