import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { setLogger } from 'react-query'

import { debug, sentryDSN } from '@monorepo/core/config'

Sentry.init({
  dsn: sentryDSN,
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  // tracesSampleRate: 1.0,
  tracesSampler: () => (debug ? false : 0.5),
})

// Sentry logger
setLogger({
  log: (message) => {
    Sentry.captureMessage(message)
  },
  warn: (message) => {
    Sentry.captureMessage(message)
  },
  error: (error) => {
    Sentry.captureException(error)
  },
})
