import styled from 'styled-components'

import { SearchField as BaseSearchField } from './SearchField'

export type { SearchFieldProps } from './SearchField'

export const SearchField = styled(BaseSearchField)`
  color: ${(props) => props.theme.colorBlack};
  line-height: 1.5em;

  & > form {
    margin: 0;

    & label {
      color: ${(props) => props.theme.colorWhite};
    }
  }
`
