import React, { useEffect } from 'react'

import { useClearCurrentUser, useCurrentUser } from '../hooks'
import { useAuthenticatation } from '@monorepo/auth_module'

export const UserProvider: React.FC = ({ children }) => {
  const { isAuthenticated } = useAuthenticatation()
  const { data: user } = useCurrentUser()
  const { clearUser } = useClearCurrentUser()

  useEffect(() => {
    if (!isAuthenticated && user) {
      clearUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  return <>{children}</>
}
