import { useEffect } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { uniqBy } from 'lodash/fp'

import { IGood, IOffer } from '@monorepo/application_module'
import { IVendor } from '@monorepo/vendor_module'
import { useCurrentUser } from '@monorepo/user_module'
import { fetchOffersBy } from '../api'
import { useRemoteApi } from './remote_api.hook'

const uniqueOffers = uniqBy<IOffer>('itemKey')

type UseSearchResultParams = {
  number: string
  brandName: string
}

const QUERY_KEY = 'search-offers'

export function useSearchResult(params: UseSearchResultParams) {
  const { data: user } = useCurrentUser()
  const query = useQuery(
    [QUERY_KEY, { user: user?.id ?? null }, { ...params }],
    fetchOffersBy.bind(null, {
      number: params.number,
      brandName: params.brandName,
      crosses: true,
    }),
    {
      refetchOnWindowFocus: false,
    }
  )
  const queryClient = useQueryClient()

  const fetcher = (vendor: IVendor) => {
    const filter = {
      number: params.number,
      brandName: params.brandName,
      crosses: true,
      vendor: vendor.id,
    }

    return queryClient.fetchQuery(
      [QUERY_KEY, { user: user?.id ?? null }, filter],
      fetchOffersBy.bind(null, filter)
    )
  }

  const mutation = useMutation(fetcher, {
    onSuccess(data) {
      queryClient.setQueriesData<IGood[]>(
        [QUERY_KEY, { user: user?.id ?? null }, { ...params }],
        (state) => {
          const next = data.reduce((acc, good) => {
            const current = acc.find((current) => current.id === good.id)
            if (current && good.offers) {
              current.offers = uniqueOffers(
                (current.offers ?? []).concat(good.offers)
              )
            } else {
              good.offers = uniqueOffers(good.offers)
              acc.push(good)
            }
            return acc
          }, ([] as IGood[]).concat(state ?? []))

          return next
        }
      )
    },
  })

  const { data: api } = useRemoteApi({ enabled: query.isFetched })

  useEffect(() => {
    if (Boolean(api?.length) && query.isFetched) {
      api?.forEach((vendor) => mutation.mutate(vendor))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, query.isFetched])

  return {
    ...query,
    isFetching: query.isFetching || mutation.isLoading,
    isFetched: query.isFetched && !mutation.isLoading,
    isLoading: query.isLoading || mutation.isLoading,
  }
}
