import React, { useCallback, useContext, useState } from 'react'
import invariant from 'invariant'

import { AddToCartContext, IAddToCartContext } from './add_to_cart.context'
import { IOffer } from '@monorepo/application_module'

// import { AuthContext } from './auth.context'
// import { apiClient } from '@monorepo/core'
// import { AxiosRequestConfig } from 'axios'
// import { refreshTokens, authenticate } from '../api'
// import { LoginDTO, ForgotPasswordDTO, RegistrationDTO } from '../../dto'

type AddToCartProviderProps = {
  children?: ((props: IAddToCartContext) => React.ReactNode) | React.ReactNode
}

export const AddToCartProvider: React.FC<AddToCartProviderProps> = ({
  children,
}) => {
  const [offer, setOffer] = useState<IOffer>()

  const addToCart = useCallback((offer: IOffer) => {
    setOffer(offer)
  }, [])
  // const addToCart = useCallback(async (dto: any) => {

  const close = useCallback(() => setOffer(undefined), [])

  const contextValue = {
    isAdding: Boolean(offer),
    offer,
    addToCart,
    close,
  }

  return (
    <AddToCartContext.Provider value={contextValue}>
      {typeof children === 'function' ? children(contextValue) : children}
    </AddToCartContext.Provider>
  )
}

export function useAddToCart() {
  const context = useContext(AddToCartContext)

  invariant(context, 'Add [AddToCartProvider] to your app!')

  return context
}
