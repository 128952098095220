import styled from 'styled-components'

import type { ButtonOptions } from '../types'
import { BaseBlock } from './base_block'

export const HoverBlock: React.FC<ButtonOptions> = styled(BaseBlock)`
  z-index: 3;
  background-color: ${(props) =>
    props.$inverted || props.$variant === 'link'
      ? props.theme.secondaryHover
      : props.theme[props.$variant + 'Hover']};
  transition: transform 700ms cubic-bezier(0.19, 1, 0.22, 1);
  box-shadow: inset 0 0 0 160px rgb(255 255 255 / 10%);
  width: 100%;
  height: 100%;
  transform: scaleX(0);
  transform-origin: bottom right;
`
