import React from 'react'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb, faMoon } from '@fortawesome/free-regular-svg-icons'

import { useThemeVariant } from 'App/Layout/theme'

type ThemeSwitcherProps = {
  //
}

export const ThemeSwitcher: React.FC<ThemeSwitcherProps> = () => {
  const { nextThemeVariant, isDark } = useThemeVariant()
  return (
    <Button
      onClick={nextThemeVariant}
      variant="link"
      title={`${isDark ? 'Светлая' : 'Тёмная'} тема`}
    >
      <FontAwesomeIcon
        icon={isDark ? faLightbulb : faMoon}
        size="lg"
        fixedWidth
      />
    </Button>
  )
}
