import { useState, useEffect } from 'react'

function getStorageValue<T>(key: string, defaultValue: T) {
  // getting stored value
  if (typeof window !== 'undefined') {
    const saved = localStorage.getItem(key)
    const initial = saved ? JSON.parse(saved) : null
    return initial || defaultValue
  }
}

// type result<T> = [T, (value: T) => void]
export function useLocalStorage<T>(key: string, defaultValue: T) {
  const [value, setValue] = useState<T>(() => {
    return getStorageValue<T>(key, defaultValue)
  })

  useEffect(() => {
    // storing input name
    localStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return { value, setValue }
}
