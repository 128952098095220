import * as Yup from 'yup'

import { addressSuggestionShema } from '@monorepo/dadata_module'
import { RegistrationDTO } from '../dto'

export const registrationSchema: Yup.SchemaOf<RegistrationDTO> = Yup.object({
  email: Yup.string().label('email').email().required(),
  plainPassword: Yup.object({
    first: Yup.string().label('Пароль').min(6).required(),
    second: Yup.string()
      .label('Повторение пароля')
      .when({
        is: (value?: string) => Boolean(value),
        then: (schema) =>
          schema.oneOf([Yup.ref('first')], 'Пароли не совпадают'),
      })
      .required(),
  }),
  phone: Yup.string().label('Телефон').phone().required(),
  isSendNotification: Yup.boolean().label('Получать уведомления').required(),
  isWholesale: Yup.boolean().label('Оптовый клиент').required(),
  firstName: Yup.string().label('Имя').min(2).default('').required(),
  middleName: Yup.string().label('Отчество').default('').optional(),
  lastName: Yup.string().label('Фамилия').min(2).default('').required(),
  // address: addressSuggestionShema.label('Адрес').nullable().required(),
  address: Yup.lazy((value: any) => {
    switch (typeof value) {
      case 'object':
        return addressSuggestionShema.label('Адрес').nullable().required()
      case 'string':
        return Yup.string()
      default:
        return Yup.mixed() // here you can decide what is the default
    }
  }),
  office: Yup.object({
    id: Yup.number().nullable().default(undefined),
  })
    // .shape({
    //   id: Yup.string(),
    // })
    .label('Офис обслуживания')
    .required(),
})
