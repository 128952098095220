import React from 'react'
import cn from 'classnames'
import gravatar from 'gravatar'

import { useAuthenticatation } from '@monorepo/auth_module'
import { IUser } from '@monorepo/user_module'
import { Link } from 'App/Components'

type UserButtonProps = {
  user?: IUser
}

export const UserButton: React.FC<UserButtonProps> = ({ user }) => {
  const { isAuthenticated } = useAuthenticatation()

  return (
    <div className="header-icon hbe hbe-icon-element hide-default-false hide-sticky-false hbe-right size-medium">
      <Link
        to={isAuthenticated ? '/cabinet' : '/login'}
        className={cn('hbe-toggle', 'hicon', 'enova', {
          'ien-euser-10': !isAuthenticated,
        })}
      >
        {isAuthenticated && user && (
          <img
            width={30}
            height={30}
            className="rounded-circle"
            style={{ marginBottom: 3 }}
            src={gravatar.url(user.email!, { default: 'mp' })}
            alt=""
          />
        )}
      </Link>
    </div>
  )
}
