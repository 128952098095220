import { IAddress } from '../interfaces'

export function renderFullAddress(address: IAddress) {
  return [
    address.country,
    address.city,
    address.street,
    address.line1,
    address.line2,
    address.line3,
  ]
    .filter(Boolean)
    .join(', ')
}
