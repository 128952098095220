import React from 'react'
import { Table } from 'react-bootstrap'

import { IDeliveryStatistics } from '@monorepo/vendor_module'

type TimeStatisticsTableProps = {
  data: IDeliveryStatistics['timeStatistics']
}

export const TimeStatisticsTable: React.FC<TimeStatisticsTableProps> = ({
  data: { declared, minimum, average, maximum },
}) => (
  <Table size="sm">
    <tbody>
      <tr>
        <td className="text-right small">Заявленный срок:</td>
        <td className="small">{declared} дн.</td>
        <td className="text-right small">Минимальный срок:</td>
        <td className="small">{minimum} дн.</td>
      </tr>
      <tr>
        <td className="text-right small">Средний срок:</td>
        <td className="small">{average} дн.</td>
        <td className="text-right small">Максимальный срок:</td>
        <td className="small">{maximum} дн.</td>
      </tr>
    </tbody>
  </Table>
)
