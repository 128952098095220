import { useCallback, useEffect, useState } from 'react'
import defer from 'lodash/defer'
import { useHistory } from 'react-router'

type Options = {
  isVisible?: boolean
  onShow?(): void
  onHide?(): void
  ref?: React.MutableRefObject<HTMLElement | null>
  // ref?: React.Ref<HTMLElement>
}

export function useComponentVisible({
  isVisible: initial = false,
  ref,
  onShow,
  onHide,
}: Options = {}) {
  const [isVisible, setVisible] = useState(initial)
  const history = useHistory()

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        ref?.current &&
        !ref.current.contains(event.target as Node) &&
        isVisible
      ) {
        defer(setVisible, false)
      }
    },
    [isVisible, ref]
  )

  const show = useCallback(() => {
    setVisible(true)
    onShow?.()
  }, [onShow])

  const hide = useCallback(() => {
    setVisible(false)
    onHide?.()
  }, [onHide])

  const toggle = useCallback(
    () => (isVisible ? hide() : show()),
    [hide, isVisible, show]
  )

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    const unlisten = history.listen(hide)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
      unlisten()
    }
  }, [handleClickOutside, hide, history])

  return {
    isVisible,
    show,
    hide,
    toggle,
  }
}
