import React from 'react'
import styled from 'styled-components'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'

type CalloutVariant = 'primary' | 'secondary' | 'warning' | 'danger'

type CalloutProps = React.HTMLAttributes<HTMLSpanElement> & {
  loading?: boolean
  icon?: IconProp
  variant?: CalloutVariant
  title?: React.ReactNode
}

const Container = styled.div<{ variant?: CalloutVariant }>`
  border: 2px solid ${(props) => props.theme[props.variant ?? 'primary']};
  border: 2px solid ${(props) => props.theme[props.variant ?? 'primary']};
  border-left-width: 16px;
  color: ${(props) => props.theme[props.variant ?? 'primary']};
  border-radius: ${(props) => props.theme.baseRadius};
  /* box-shadow: ${(props) => props.theme.baseBoxShadow}; */
  padding: 1.5rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
`

const Content = styled.span`
  width: 100%;
`

const Title = styled.h4`
  margin-bottom: 0px;
`

export const Callout: React.FC<CalloutProps> = ({
  children,
  loading,
  icon,
  variant,
  title,
  ...props
}) => {
  const currentIcon = loading ? faCog : icon

  return (
    <Container variant={variant}>
      {typeof currentIcon !== 'undefined' && (
        <FontAwesomeIcon
          className="mr-3"
          icon={currentIcon}
          spin={loading}
          size="3x"
          fixedWidth
        />
      )}
      <Content {...props}>
        {title && <Title>{title}</Title>}
        {children}
      </Content>
    </Container>
  )
}
