import { StringSchema, string, addMethod } from 'yup'
import { Message } from 'yup/lib/types'

import { string as messages } from '../locales/ru'

addMethod<StringSchema>(
  string,
  'phone',
  function (region?: string, message = messages.phone) {
    return this.test({
      name: 'phone',
      // exclusive: true,
      message,
      test: async (value) => {
        if (!value) return true
        try {
          const { PhoneNumberUtil } = await import('google-libphonenumber')
          const phoneUtil = PhoneNumberUtil.getInstance()
          const phone = phoneUtil.parse(value, region)
          return phoneUtil.isValidNumber(phone)
        } catch (e) {
          return false
        }
      },
    })
  }
)

declare module 'yup' {
  interface StringSchema {
    phone(
      region?: string,
      message?: string | ((params: Message) => string)
    ): StringSchema
  }
}
