import React, { useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import {
  FormikContextType,
  FormikHelpers,
  FormikProvider,
  useFormik,
} from 'formik'

import { LoginDTO } from '../dto/login.dto'
import { FormError } from '@monorepo/application_module/components/form_error'
import { useAuthenticatation } from '../auth_provider'

type LoginProviderProps = {
  onSuccess?(): void
  onError?(error: FormError): void
  children:
    | ((props: FormikContextType<LoginDTO>) => React.ReactNode)
    | React.ReactNode
}

export const LoginProvider: React.FC<LoginProviderProps> = ({
  children,
  onSuccess,
  onError,
}) => {
  const { login, isAuthenticated } = useAuthenticatation()

  const history = useHistory()
  const { state } = useLocation<{ referrer?: string }>()

  useEffect(() => {
    if (isAuthenticated) {
      history.push(state?.referrer ?? '/')
    }
  }, [history, isAuthenticated, state])
  const initialValues: LoginDTO = {
    username: '',
    password: '',
  }

  const submitHandler = useCallback(
    async (values: LoginDTO, _helpers: FormikHelpers<LoginDTO>) => {
      try {
        await login(values)
        state?.referrer && history.push(state.referrer)
        onSuccess?.()
      } catch (error) {
        onError?.(
          FormError.isFormError(error) ? error : FormError.create(error)
        )
      }
    },
    [history, login, onError, onSuccess, state]
  )

  const formik: FormikContextType<LoginDTO> = useFormik<LoginDTO>({
    initialValues,
    onSubmit: submitHandler,
    enableReinitialize: false, // IMPORTANT: set false!
    // validationSchema: createCartItemSchema,
  })

  return (
    <FormikProvider value={formik}>
      {typeof children === 'function' ? children(formik) : children}
    </FormikProvider>
  )
}
