import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentUser } from '@monorepo/user_module'
import { useAuthenticatation } from '@monorepo/auth_module'
import { IContract } from '../interfaces'
import { fetchContracts } from '../api'

export const QUERY_KEY = 'contracts'

export function useContracts<TData = IContract[]>(
  options?: UseQueryOptions<IContract[], AxiosError, TData>
) {
  const { data: user } = useCurrentUser()
  const { isAuthenticated } = useAuthenticatation()

  return useQuery([QUERY_KEY, { user: user?.id }] as any, fetchContracts, {
    // refetchOnWindowFocus: false,
    // staleTime: 60 * 1000,
    staleTime: 15 * 1000,
    retry: 1,
    enabled: isAuthenticated && Boolean(user?.id),
    ...options,
  })
}
