import axios from 'axios'

import { IGood } from '@monorepo/application_module'
import { VendorId } from '@monorepo/vendor_module'
import { apiClient } from '@monorepo/core'

export function fetchSuggestions(
  number: string,
  options?: { strict?: boolean; useFTS?: boolean; vendor?: VendorId }
) {
  try {
    const source = axios.CancelToken.source()

    const promise: Promise<IGood[]> & {
      cancel?(): void
    } = apiClient
      .get<IGood[]>('/search/goods', {
        cancelToken: source.token,
        params: { ...options, number },
      })
      .then((response) => response.data)

    promise.cancel = source.cancel.bind(
      null,
      'Query was cancelled by React Query'
    )

    return promise
  } catch (error) {
    throw error
  }
}
