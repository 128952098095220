import React, { useCallback, useMemo, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { faBan, faInfo } from '@fortawesome/free-solid-svg-icons'

import { IOffer } from '@monorepo/application_module'
import { Icon, IconButton } from './icon_button'
import { CompositButton } from './Button'

type RefundInfoProps = {
  item: IOffer
}

export const RefundInfo: React.FC<RefundInfoProps> = ({ item }) => {
  const [show, setShow] = useState(false)

  const showHandler = useCallback(() => setShow(true), [setShow])
  const hideHandler = useCallback(() => setShow(false), [setShow])

  const { isAllowed, percent, conditions } = useMemo(() => {
    const { isAllowed = true, percent = 0, conditions = '' } = item.refund ?? {}

    return { isAllowed, percent, conditions }
  }, [item.refund])

  if (isAllowed && percent === 0 && !Boolean(conditions)) {
    return null
  }

  const title = !isAllowed
    ? 'Возврат товара запрещён'
    : percent > 0
    ? `Возврат товара ${percent}%`
    : 'Внимание!'

  return (
    <>
      <OverlayTrigger
        placement="auto"
        overlay={
          <Popover id="refund-popover">
            <Popover.Title>{title}</Popover.Title>
            {isAllowed && Boolean(conditions) && (
              <Popover.Content>{conditions}</Popover.Content>
            )}
          </Popover>
        }
      >
        {!isAllowed ? (
          <IconButton onClick={showHandler} variant="danger">
            <Icon icon={faBan} />
          </IconButton>
        ) : (
          <IconButton
            onClick={showHandler}
            variant={percent > 0 ? 'warning' : undefined}
          >
            {percent > 0 ? <small>{percent}%</small> : <Icon icon={faInfo} />}
          </IconButton>
        )}
      </OverlayTrigger>
      <Modal show={show} onHide={hideHandler}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{conditions && <p>{conditions}</p>}</Modal.Body>
        <Modal.Footer>
          <Col xs={12} className="text-center">
            <CompositButton onClick={hideHandler} outline>
              Закрыть
            </CompositButton>
          </Col>
        </Modal.Footer>
      </Modal>
    </>
  )
}
