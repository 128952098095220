import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import scrollIntoView from 'scroll-into-view-if-needed'

export type MenuItemProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  href: string
  active?: boolean
}

export const MenuItem: React.FC<MenuItemProps> = React.memo(
  ({ href, active, ...props }) => {
    const itemRef = useRef<HTMLAnchorElement>(null)

    useEffect(() => {
      if (active) {
        const node = itemRef.current
        node &&
          scrollIntoView(node, {
            block: 'nearest',
            inline: 'nearest',
            scrollMode: 'if-needed',
            behavior: 'smooth',
          })
      }
    }, [active])

    return <Link to={href} ref={itemRef} role="menuitem" {...props} />
  }
)
