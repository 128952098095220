interface IWorkinhHours {
  startsAt: string
  endsAt: string
}

export enum WorkDayName {
  mon = 'mon',
  tue = 'tue',
  wed = 'wed',
  thu = 'thu',
  fri = 'fri',
  sat = 'sat',
  sun = 'sun',
}

export type IWorkSchedule = Record<WorkDayName, IWorkinhHours | null>
