import qs from 'qs'

// import { IGood } from '../interfaces'

type UrlType = 'search' | 'catalog'

interface IGood {
  number: string
  brandName?: string
  brand: {
    name: string
  }
}

export function getSearchUrl(good: IGood) {
  let { brandName, brand, number } = good

  if (typeof brand?.name !== 'undefined') {
    brandName = brand.name
  }
  return `/search?${qs.stringify({ brandName, number })}`
}

export function getCatalogUrl(good: IGood) {
  let { brandName, brand, number } = good

  if (typeof brand?.name !== 'undefined') {
    brandName = brand.name
  }
  return `/goods/${brandName}/${number}`
}

export function getUrl(good: IGood, type: UrlType = 'search') {
  switch (type) {
    case 'search':
      return getSearchUrl(good)
    case 'catalog':
      return getCatalogUrl(good)
    default:
      return getSearchUrl(good)
  }
}
