import React from 'react'
import { Bar } from 'react-chartjs-2'
import { map } from 'lodash'

import { IDeliveryStatistics } from '@monorepo/vendor_module'

type IssuesQualityProps = {
  width: number
  height: number
  data: IDeliveryStatistics['timeTable']
}

const options = {
  responsive: true,
  legend: false,
  title: {
    display: true,
    text: 'Выполнено заказов, %',
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          callback: (value: number) => `${value}%`,
        },
      },
    ],
  },
}

export const IssuesQuality: React.FC<IssuesQualityProps> = ({
  height,
  width,
  data: statistics,
}) => {
  const data = {
    labels: map(statistics, ({ x }) => x),
    datasets: [
      {
        data: map(statistics, ({ y }) => y),
        backgroundColor: 'rgba(139, 209, 97, 0.75)',
        borderColor: 'rgba(139, 209, 97, 1)',
        borderWidth: 1,
        label: 'Вероятность поставки',
        datalabels: {
          anchor: 'end',
          offset: -17,
          align: 'start',
          borderRadius: 4,
          formatter: (value: number) => `${value}%`,
        },
      },
    ],
  }

  return <Bar data={data} options={options} width={width} height={height} />
}
