import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'

import { useAuthenticatation } from '@monorepo/auth_module'
import { useCurrentUser } from '@monorepo/user_module'
import { IOperationTransaction, ITransactionsFilter } from '../interfaces'
import { fetchOperationTransaction } from '../api'

const QUERY_KEY = 'contract-operation-transactions'

export function useTransactions<TData = IOperationTransaction>(
  filter: ITransactionsFilter,
  options?: UseQueryOptions<IOperationTransaction, AxiosError, TData>
) {
  const { data: user } = useCurrentUser()
  const { isAuthenticated } = useAuthenticatation()

  return useQuery(
    [QUERY_KEY, { user: user?.id, ...filter }] as any,
    () => fetchOperationTransaction(filter),
    {
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
      retry: 1,
      enabled:
        isAuthenticated && Boolean(user?.id) && Boolean(filter?.operationId),
      ...options,
    }
  )
}
