import { orderBy } from 'lodash/fp'

import { IOffer } from '@monorepo/application_module'

export enum sortOrders {
  price = 'price',
  deliveryTime = 'deliveryTime',
}

export function sortOffersBy(prop: sortOrders) {
  return orderBy<IOffer>(
    [
      'isStock',
      prop,
      ...Object.values(sortOrders).filter((current) => current !== prop),
      'amount',
    ] as (keyof IOffer)[],
    ['desc', 'asc', 'asc', 'desc']
  )
}
