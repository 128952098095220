import { useMutation, useQueryClient } from 'react-query'

import { useCurrentUser } from '@monorepo/user_module'
import { IContract } from '../interfaces'
import { setCurrentContract } from '../api/set_current_contract.api'
import { QUERY_KEY } from './contracts.hook'

export function useCurrentContractSelector() {
  const queryClient = useQueryClient()
  const { data: user } = useCurrentUser()

  const { mutate, mutateAsync, ...mutation } = useMutation(
    (contract: IContract) => setCurrentContract(contract.id),
    {
      onSuccess: (data, _variables) => {
        queryClient.setQueryData<IContract[]>(
          [QUERY_KEY, { user: user?.id }],
          (prev = []) =>
            prev.map((item) => ({
              ...item,
              isCurrent: item.id === data.id,
            }))
        )
      },
      onError: (_err, _newCartItem, context: any) => {
        // queryClient.setQueryData(QUERY_KEY, context.previousCartItems)
      },
      // Always refetch after error or success:
      // onSettled: (newTodo) => {
      //   queryClient.invalidateQueries(['todos', newTodo.id])
      // },
    }
  )

  return {
    ...mutation,
    selectContract: mutate,
    selectContractAsync: mutateAsync,
  }
}
