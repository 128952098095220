import { apiClient } from '@monorepo/core'

export async function fetchCartItems() {
  try {
    const { data } = await apiClient.get<any[]>('/v2/cart')

    return data
  } catch (error) {
    return []
  }
}
