import React from 'react'
import cn from 'classnames'

import { useComponentVisible } from '@monorepo/application_module/hooks'
import { IMenuItem } from '@monorepo/menu_module'
import { Link } from 'App/Components'
import { MenuItemsList } from '../MenuItemsList'

type MenuItemProps = {
  item: IMenuItem
}

export const MenuItem: React.FC<MenuItemProps> = ({ item }) => {
  const { isVisible, toggle } = useComponentVisible()

  const toggleHandler = React.useCallback(
    (event) => {
      event.preventDefault()
      toggle()
    },
    [toggle]
  )

  return (
    <li
      className={cn(
        'menu-item',
        'menu-item-type-taxonomy',
        'menu-item-object-product_cat',
        { 'menu-item-has-children': !!item.menu }
      )}
    >
      <Link to={item.url ?? '/'}>
        <span className="txt">{item.name}</span>
        <span
          className={cn('arrow-down', { active: isVisible })}
          onClick={toggleHandler}
        />
      </Link>
      {item.menu && (
        <MenuItemsList
          items={item.menu}
          className={cn('sub-menu', { 'd-block': isVisible })}
        />
      )}
    </li>
  )
}
