import { apiClient } from '@monorepo/core'
import { FormError } from '@monorepo/application_module/components/form_error'

import { IDeliveryStatistics, VendorId } from '../interfaces'

export async function fetchDeliveryStatistics(id: VendorId) {
  try {
    const { data } = await apiClient.get<IDeliveryStatistics>(
      `/vendor/delivery/statistics/${id}`
    )
    return data
  } catch (error) {
    throw FormError.create(error)
  }
}
