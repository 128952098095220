import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import './initializers'

import UserModule from '@monorepo/user_module'
import AuthModule from '@monorepo/auth_module'
import { IModule } from './module.interface'

const queryClient = new QueryClient()

type ApplicationProps = {
  //
}

export const Application: React.FC<ApplicationProps> = ({ children }) => {
  const modules: IModule[] = [AuthModule, UserModule]

  modules.forEach((module) => {
    module.init?.()
  })

  return (
    <QueryClientProvider client={queryClient}>
      {modules.reverse().reduce((acc, { Provider }) => {
        return Provider ? <Provider>{acc}</Provider> : acc
      }, children)}
      <ReactQueryDevtools /*initialIsOpen*/ />
    </QueryClientProvider>
  )
}
// export const Application: React.FC<ApplicationProps> = ({ children }) => (
//   <QueryClientProvider client={queryClient}>
//     <AuthProvider>
//       <UserProvider>{children}</UserProvider>
//     </AuthProvider>
//     <ReactQueryDevtools /*initialIsOpen*/ />
//   </QueryClientProvider>
// )
