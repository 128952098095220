import React, { useCallback } from 'react'
import {
  FormikContextType,
  FormikHelpers,
  FormikProvider,
  useFormik,
} from 'formik'

import { IOffer } from '@monorepo/application_module'
import { FormError } from '@monorepo/application_module/components/form_error'
import type { ICartItem } from '../interfaces'
import { CreateCartItemDTO } from '../dto'
import { useCreateCartItem } from '../hooks/crud'
import { createCartItemSchema } from '../schema'

type CreateCartItemProviderProps = {
  item: IOffer
  onSuccess?(cartItem: ICartItem): void
  onError?(errors: FormError): void
  children:
    | ((props: FormikContextType<CreateCartItemDTO>) => React.ReactNode)
    | React.ReactNode
}

export const CreateCartItemProvider: React.FC<CreateCartItemProviderProps> = ({
  children,
  item,
  onSuccess,
  onError,
}) => {
  const initialValues: CreateCartItemDTO = {
    itemKey: item.itemKey,
    amount: item.multiplicityAmount ?? 1,
    reference: '',
  }
  const { mutateAsync } = useCreateCartItem()

  const submitHandler = useCallback(
    async (
      values: CreateCartItemDTO,
      _helpers: FormikHelpers<CreateCartItemDTO>
    ) => {
      try {
        // const dto: TypeOf<typeof createCartItemSchema> =
        // createCartItemSchema.cast(values)
        const cartItem = await mutateAsync(values)

        onSuccess?.(cartItem)
      } catch (error) {
        onError?.(FormError.isFormError(error) ? error : new FormError())
      }
    },
    [mutateAsync, onError, onSuccess]
  )

  const formik: FormikContextType<CreateCartItemDTO> =
    useFormik<CreateCartItemDTO>({
      initialValues,
      onSubmit: submitHandler,
      enableReinitialize: false, // IMPORTANT: set false!
      validationSchema: createCartItemSchema,
    })
  return (
    <FormikProvider value={formik}>
      {typeof children === 'function' ? children(formik) : children}
    </FormikProvider>
  )
}
