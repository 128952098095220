import styled, { css } from 'styled-components'

import type { ButtonOptions } from '../types'

const linkStyle = css<ButtonOptions>`
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  content: '';
  height: 100%;
  width: 100%;
  margin-top: ${(props: any) => {
    const { baseFontSize } = props.theme
    const baseFontSizeParsed = parseInt(baseFontSize, 10)
    return (
      (props.$size === 'small' && `${(baseFontSizeParsed * 0.875) / 2}px`) ||
      (props.$size === 'large' && `${(baseFontSizeParsed * 1.375) / 2}px`) ||
      `${baseFontSizeParsed / 2}px`
    )
  }};

  transition: border-color 300ms ease-out, background-color 300ms ease-out,
    opacity 300ms ease-out, transform 600ms cubic-bezier(0.19, 1, 0.22, 1);
  border-top-style: solid;
  border-top-width: 2px;

  opacity: 0;
  z-index: 10;
  border-radius: inherit;
  transform: scaleX(0);
  transform-origin: bottom right;
`

export const TextBlock = styled.span<ButtonOptions>`
  position: relative;
  z-index: 4;
  display: inline-block;
  vertical-align: middle;
  padding-top: ${(props) => (props.$size === 'small' && '3px') || '6px'};
  padding-bottom: ${(props) => (props.$size === 'small' && '3px') || '6px'};
  font-size: ${(props) => {
    const { baseFontSize } = props.theme
    const baseFontSizeParsed = parseInt(baseFontSize, 10)
    return (
      (props.$size === 'small' && `${baseFontSizeParsed * 0.875}px`) ||
      (props.$size === 'large' && `${baseFontSizeParsed * 1.375}px`) ||
      baseFontSize
    )
  }};
  line-height: ${(props) =>
    (props.$size === 'small' && '1.25') ||
    (props.$size === 'large' && '1.25') ||
    '1.75'};

  &:after {
    ${(props) => props.$variant === 'link' && linkStyle}
  }
`
