import { useQuery, UseQueryOptions } from 'react-query'

import { IDeliveryStatistics, IVendor } from '../interfaces'
import { fetchDeliveryStatistics } from '../api'
import { FormError } from '@monorepo/application_module/components/form_error'

const QUERY_KEY = 'fetchDeliveryStatisticsMethods'

export const useStatistics = <TData = IDeliveryStatistics>(
  vendor: IVendor,
  options?: UseQueryOptions<IDeliveryStatistics, FormError, TData>
) => {
  return useQuery(
    [QUERY_KEY, { id: vendor.id }] as any,
    () => fetchDeliveryStatistics(vendor.id),
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 10,
      ...options,
    }
  )
}
