import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'
import { DaDataAddress, DaDataSuggestion } from 'react-dadata'

import { fetchAddressSuggestions } from '../api'

const QUERY_KEY = 'dadata_suggestion_address'

export function useAddressSuggestions<
  TData = DaDataSuggestion<DaDataAddress>[]
>(
  query: string = '',
  options?: UseQueryOptions<
    DaDataSuggestion<DaDataAddress>[],
    AxiosError,
    TData
  >
) {
  return useQuery(
    [QUERY_KEY, query.toLowerCase()] as any,
    () => fetchAddressSuggestions(query),
    {
      enabled: query.length >= 3,
      staleTime: 60 * 60 * 1000,
      refetchOnWindowFocus: false,
      ...options,
      // enabled: true,
    }
  )
}
