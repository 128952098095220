import React from 'react'
import {
  FormikContextType,
  FormikHelpers,
  FormikProvider,
  useFormik,
  useFormikContext,
} from 'formik'

import { useOfficesList } from '@monorepo/company_module'
import { FormError } from '@monorepo/application_module/components/form_error'
import { useAuthenticatation } from '@monorepo/auth_module'
import { RegistrationDTO } from '../dto'
import { registrationSchema } from '../schema'

type RegistrationProviderProps = {
  email?: string
  onSuccess?(dto: RegistrationDTO): void | Promise<void>
  onError?(error: FormError): void
  children:
    | ((props: FormikContextType<RegistrationDTO>) => React.ReactNode)
    | React.ReactNode
}

export const RegistrationProvider: React.FC<RegistrationProviderProps> = ({
  children,
  onSuccess,
  onError,
  email,
}) => {
  const { data: defaultOffice } = useOfficesList({
    select: (offices) =>
      offices.find((office) => office.isMainRetail) ?? offices[0],
  })
  const { login, registration } = useAuthenticatation()

  const initialValues: RegistrationDTO = {
    email: email ?? '',
    plainPassword: {
      first: '',
      second: '',
    },
    phone: '',
    isSendNotification: false,
    isWholesale: false,
    firstName: '',
    middleName: '',
    lastName: '',
    address: '',
    office: defaultOffice,
  }
  // const history = useHistory()

  const onSubmit = async (
    values: RegistrationDTO,
    helpers: FormikHelpers<RegistrationDTO>
  ) => {
    const dto = registrationSchema.cast(values, {
      stripUnknown: true,
    }) as RegistrationDTO

    try {
      await registration(dto)

      onSuccess?.(dto)

      await login({
        username: values.email,
        password: values.plainPassword.first,
      })

      helpers.setSubmitting(false)
    } catch (error) {
      helpers.setSubmitting(false)
      onError?.(
        FormError.isFormError(error)
          ? error
          : new FormError('Ошибка при регистрации')
      )
    }
  }

  const formik: FormikContextType<RegistrationDTO> = useFormik<RegistrationDTO>(
    {
      initialValues,
      onSubmit,
      enableReinitialize: true,
      validationSchema: registrationSchema,
    }
  )

  return (
    <FormikProvider value={formik}>
      {typeof children === 'function'
        ? (
            children as (
              props: FormikContextType<RegistrationDTO>
            ) => React.ReactNode
          )(formik)
        : children}
    </FormikProvider>
  )
}

export function useRegistrationForm() {
  return useFormikContext<RegistrationDTO>()
}
