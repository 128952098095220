import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'

import { useAuthenticatation } from '@monorepo/auth_module'
import { useCurrentUser } from '@monorepo/user_module'
import { IContractOperation, IOperationsFilter } from '../interfaces'
import { fetchContractOperations } from '../api'

const QUERY_KEY = 'contract-operations'

export function useOperations<TData = IContractOperation[]>(
  filter: IOperationsFilter,
  options?: UseQueryOptions<IContractOperation[], AxiosError, TData>
) {
  const { data: user } = useCurrentUser()
  const { isAuthenticated } = useAuthenticatation()

  return useQuery(
    [QUERY_KEY, { user: user?.id, ...filter }] as any,
    () => fetchContractOperations(filter),
    {
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
      retry: 1,
      enabled: isAuthenticated && Boolean(filter?.contract?.id),
      ...options,
    }
  )
}
