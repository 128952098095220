import styled from 'styled-components'

import { Link } from 'App/Components/Link'

export const MenuItem = styled(Link)`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  transition: all 0.5s ease-in-out;
  padding: 12px 24px;

  &:hover {
    background-color: ${(props) => props.theme.primary};
    color: ${(props) => props.theme.brandWhite};
  }
`
