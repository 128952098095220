import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'

import { ICompany } from '@monorepo/company_module'
import { fetchOfficesList } from '../api'
import { sortOffice } from '../helpers'

const QUERY_KEY = 'offices'

export function useOfficesList<TData = ICompany[]>(
  options?: UseQueryOptions<ICompany[], AxiosError, TData>
) {
  return useQuery(QUERY_KEY as any, fetchOfficesList, {
    refetchOnWindowFocus: false,
    ...options,
    select: (items) =>
      options?.select
        ? (options.select(sortOffice(items)) as TData)
        : (sortOffice(items) as unknown as TData),
  })
}
