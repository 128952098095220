import { filter, flow, get, isEmpty } from 'lodash/fp'
import { IDeliveryStatistics } from '@monorepo/vendor_module'

export const isDeliveryStatisticsEmpty = flow(
  get<IDeliveryStatistics, keyof IDeliveryStatistics>('deliveryStatistics'),
  isEmpty
)
export const isTimeTableEmpty = flow(
  get<IDeliveryStatistics, keyof IDeliveryStatistics>('timeTable'),
  isEmpty
)
export const isTimeStatisticsEmpty = flow(
  get<IDeliveryStatistics, keyof IDeliveryStatistics>('timeStatistics'),
  filter(Boolean),
  isEmpty
)
