import styled from 'styled-components'

import { SuggestionsList as BaseSuggestionsList } from './SuggestionsList'

export const SuggestionsList = styled(BaseSuggestionsList)`
  overflow: auto;
  margin: 0 -0.5rem;
  padding: 0 0.5rem;
  /* border-top: 1px solid ${(props) => props.theme.colorGrey}; */
`
