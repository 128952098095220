import React from 'react'

import { useCartTotalAmount } from '@monorepo/cart_module'
import { Link } from 'App/Components'

type CartButtonProps = {
  //
}

export const CartButton: React.FC<CartButtonProps> = () => {
  const { data: totalItems = 0 } = useCartTotalAmount()

  return (
    <div className="header-icon hbe hbe-icon-element hide-default-false hide-sticky-false hbe-right size-large">
      <Link
        to="/cart"
        className={`hbe-toggle hicon enova ien-ecart-${
          totalItems > 0 ? 9 : 11
        }`}
      />
    </div>
  )
}
