import React from 'react'
import {
  FormikContextType,
  FormikHelpers,
  FormikProvider,
  useFormik,
  useFormikContext,
} from 'formik'

import { FormError } from '@monorepo/application_module/components/form_error'
import { UpdateProfileDTO } from '../dto'
import { useCurrentUser, useUpdateProfile } from '../hooks'
import { IUser } from '../interfaces'

type UpdateProfileProviderProps = {
  // items: ICartItem[]
  onSuccess?(user: IUser): void | Promise<void>
  onError?(error: FormError): void
  children:
    | ((props: FormikContextType<UpdateProfileDTO>) => React.ReactNode)
    | React.ReactNode
}

export const UpdateProfileProvider: React.FC<UpdateProfileProviderProps> = ({
  children,
  onSuccess,
  onError,
}) => {
  const { data: user } = useCurrentUser()
  const { mutateAsync } = useUpdateProfile()

  const initialValues: UpdateProfileDTO = {
    phone: user?.phone ?? '',
    isSendNotification: user?.isSendNotification ?? false,
    isWholesale: user?.isWholesale ?? false,
    firstName: user?.firstName ?? '',
    middleName: user?.middleName ?? '',
    lastName: user?.lastName ?? '',
    address: user?.address ?? '',
    office: user?.office,
  }

  const onSubmit = async (
    values: UpdateProfileDTO,
    helpers: FormikHelpers<UpdateProfileDTO>
  ) => {
    // const dto: TypeOf<typeof createOrderSchema> = createOrderSchema.cast(values)
    const dto = values
    try {
      const user = await mutateAsync(dto)
      //   const order = await mutateAsync(dto)

      user && (await onSuccess?.(user))
      helpers.setSubmitting(false)
    } catch (error) {
      helpers.setSubmitting(false)
      onError?.(
        FormError.isFormError(error)
          ? error
          : new FormError('Ошибка при обновлении')
      )
    }
  }

  const formik: FormikContextType<UpdateProfileDTO> =
    useFormik<UpdateProfileDTO>({
      initialValues,
      onSubmit,
      enableReinitialize: true,
      validateOnMount: true,
      // validationSchema: createOrderSchema,
    })

  return (
    <FormikProvider value={formik}>
      {typeof children === 'function'
        ? (
            children as (
              props: FormikContextType<UpdateProfileDTO>
            ) => React.ReactNode
          )(formik)
        : children}
    </FormikProvider>
  )
}

export function useUpdateProfileForm() {
  return useFormikContext<UpdateProfileDTO>()
}
