import styled from 'styled-components'

export const PatronLogo = styled.span.attrs({
  children: 'Patron',
})`
  color: #c81c2e;
  font-weight: 800;
  text-transform: uppercase;
  font-style: oblique;
`
