import { apiClient } from '@monorepo/core'

import { IContract } from '../interfaces'

export async function fetchContracts() {
  try {
    const { data } = await apiClient.get<IContract[]>('/contracts/load')

    return data
  } catch (error) {
    return []
  }
}
