import { apiClient } from '@monorepo/core'
import { FormError } from '@monorepo/application_module/components/form_error'

import { CreateCartItemDTO } from '../dto/create_cart_item.dto'
import { ICartItem } from '../interfaces'

export async function createCartItem(dto: CreateCartItemDTO) {
  try {
    const { data } = await apiClient.post<ICartItem>(`/v2/cart/add`, dto)
    return data
  } catch (error) {
    throw FormError.create(error, 'Ошибка при добавлении')
  }
}
