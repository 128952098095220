import React from 'react'
// import { Image, ImageProps } from '@monorepo/components/common'

import imagePlaceholder from 'App/Resources/images/placeholder-300x300.png'

type ImagePlaceholderProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  //
}
// type ImagePlaceholderPropsType = Omit<ImageProps, 'src'> & {
//   //
// }

function ImagePlaceholder(props: ImagePlaceholderProps) {
  return <img {...props} src={imagePlaceholder} alt="" />
}
// const ImagePlaceholder: React.FC<ImagePlaceholderPropsType> = (props) => (
//   <img {...props} src={imagePlaceholder} alt="" />
// )

namespace ImagePlaceholder {
  export const placeholder = imagePlaceholder
}

export { ImagePlaceholder }
