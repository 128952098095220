import { IVendor } from '@monorepo/vendor_module'
import { apiClient } from '@monorepo/core'

export async function fetchApiVendors() {
  try {
    const { data } = await apiClient.get<IVendor[]>('/vendor/getRemoteVendors')

    return data
  } catch (error) {
    return []
  }
}
