import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const BreadcrumbsWrapper = styled.div`
  & > a {
    color: ${(props) => props.theme.primary}!important;
  }
  & > * {
    font-weight: ${(props) => props.theme.fontSemibold};
    font-size: 90%;
  }
`
export interface IBreadcrumb {
  readonly title: string
  readonly url?: string
}

export interface BreadcrumbsProps {
  items: IBreadcrumb[]
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items }) => (
  <BreadcrumbsWrapper className="et-breadcrumbs">
    {items.map((item, idx) =>
      item.url ? (
        <Link key={idx} to={item.url}>
          {item.title}
        </Link>
      ) : (
        <span key={idx}>{item.title}</span>
      )
    )}
  </BreadcrumbsWrapper>
)
