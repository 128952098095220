import React from 'react'
import Paginate, { ReactPaginateProps } from 'react-paginate'
import { isMobile } from 'react-device-detect'

import { IPagination } from '../../interfaces'
import { usePagination } from './hooks'

export type PaginationProps = Partial<ReactPaginateProps> & {
  meta: IPagination
}

export const Pagination: React.FC<PaginationProps> = ({
  marginPagesDisplayed,
  pageRangeDisplayed,
  meta,
  breakLabel,
  ...props
}) => {
  const { forcePage, hrefBuilder, onPageChange, pageCount } =
    usePagination(meta)

  return (
    <Paginate
      {...props}
      breakLabel={isMobile ? null : breakLabel}
      marginPagesDisplayed={marginPagesDisplayed ?? (isMobile ? 0 : 3)}
      pageRangeDisplayed={pageRangeDisplayed ?? (isMobile ? 2 : 2)}
      forcePage={forcePage}
      hrefBuilder={hrefBuilder}
      onPageChange={onPageChange}
      pageCount={pageCount}
    />
  )
}
