import React from 'react'
import { FormattedMessage } from 'react-intl'

type MessageProps = React.HTMLAttributes<HTMLElement> & {
  isVinQuery: boolean
  minLength: number
  query: string
  totalSuggestions: number
}

export const Message: React.FC<MessageProps> = ({
  isVinQuery,
  minLength,
  query,
  totalSuggestions,
  ...props
}) => {
  if (query.length < minLength) {
    return (
      <FormattedMessage
        id="search.short_query"
        defaultMessage={`
        Что ищем? Укажите не менее 
          {minLength, plural,
            one {# символа}
            few {# символов}
            many {# символов}
            other {# символов}
          }`}
        values={{
          minLength,
        }}
      >
        {(message) => <span {...props}>{message}</span>}
      </FormattedMessage>
    )
  }

  if (totalSuggestions > 0 || !isVinQuery) {
    return (
      <FormattedMessage
        id="search.offers_amount"
        defaultMessage={`
          {amount, plural,
            =0 {По запросу {query} ничего не найдено}
            one {Найден # товар}
            few {Найдено # товара}
            many {Найдено # товаров}
            other {Найдено # товаров}
          }`}
        values={{
          amount: totalSuggestions,
          query: <strong>«{query.toUpperCase()}»</strong>,
        }}
      >
        {(message) => <span {...props}>{message}</span>}
      </FormattedMessage>
    )
  }

  return null
}
