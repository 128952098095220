import { apiClient } from '@monorepo/core'
import { FormError } from '@monorepo/application_module/components/form_error'

import { ContractId, IContract } from '../interfaces'

export async function setCurrentContract(id: ContractId) {
  try {
    const { data } = await apiClient.get<IContract>(`/contracts/${id}/select`)
    return data
  } catch (error) {
    throw FormError.create(error, 'Ошибка при изменении договора')
  }
}
