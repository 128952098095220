import { useMutation, useQueryClient } from 'react-query'

import { ICartItem } from '../../interfaces'
import { removeCartItem } from '../../api'
import { QUERY_KEY } from '../cart_items.hook'

export function useRemoveCartItem() {
  const queryClient = useQueryClient()

  const {
    mutate: remove,
    mutateAsync: removeAsync,
    ...query
  } = useMutation((cartItem: ICartItem) => removeCartItem(cartItem.id), {
    onSuccess: (_data, variables) => {
      queryClient.setQueryData<ICartItem[]>(QUERY_KEY, (old) =>
        (old ?? []).filter((item) => item.id !== variables.id)
      )
    },
    // onMutate: async (newCartItem) => {
    //   // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
    //   await queryClient.cancelQueries(QUERY_KEY)

    //   // Snapshot the previous value
    //   const previousCartItems = queryClient.getQueryData<ICartItem[]>(QUERY_KEY)

    //   // Optimistically update to the new value
    //   queryClient.setQueryData<ICartItem[]>(QUERY_KEY, (old) =>
    //     (old ?? []).filter((item) => item.id !== newCartItem.id)
    //   )

    //   // Return a context with the previous and new todo
    //   return { previousCartItems }
    // },
    // If the mutation fails, use the context we returned above
    // onError: (_err, _newCartItem, context: any) => {
    //   queryClient.setQueryData(QUERY_KEY, context.previousCartItems)
    // },
    // Always refetch after error or success:
    // onSettled: (newTodo) => {
    //   queryClient.invalidateQueries(['todos', newTodo.id])
    // },
  })

  return { remove, removeAsync, ...query }
}
