import { anonymousСlient } from '@monorepo/core'

export type RefreshTokenResponse = {
  token: string
  refresh_token: string
}

export async function refreshTokens(refresh_token: string) {
  try {
    const { data } = await anonymousСlient.post<RefreshTokenResponse>(
      '/token/refresh',
      {
        refresh_token,
      }
    )

    return data
  } catch (error) {
    throw error
  }
}
