import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { apiClient } from '@monorepo/core'

import * as api from '../api'
import { RefreshTokenResponse } from '../api'
import { getRefreshToken, getToken, setTokens } from './utils'

export function requestInterceptor(config: AxiosRequestConfig) {
  const token = getToken()

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
}

let refreshTokenRequest: Promise<RefreshTokenResponse> | null = null

export async function errorResponseInterceptor(
  error: AxiosError,
  onLogout?: () => void
) {
  const originalRequest: AxiosRequestConfig & { _isRetry?: boolean } =
    error.config

  if (error.response?.status === 401 && originalRequest?._isRetry !== true) {
    originalRequest._isRetry = true
    if (!refreshTokenRequest) {
      const current_refresh_token = getRefreshToken() ?? ''
      refreshTokenRequest = api.refreshTokens(current_refresh_token)
    }

    try {
      const { token, refresh_token } = await refreshTokenRequest
      refreshTokenRequest = null
      setTokens(token, refresh_token)

      return apiClient.request(originalRequest)
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        onLogout?.()
      }
    }
  }

  throw error
}
