import { IOffer } from '@monorepo/application_module'
import { partition, sortBy } from 'lodash/fp'

const partitionOffers = partition<IOffer>('isStock')
const sortByPrice = sortBy<IOffer>(['price', 'deliveryTime'])
const sortByDeliveryTime = sortBy<IOffer>(['deliveryTime', 'price'])

export function getBestOffers(items: IOffer[], totalAmount: number = 4) {
  let [stockOffers, restOffers] = partitionOffers(items)

  const stockOffersAmount = stockOffers.length

  const cheaperOffersAmount = Math.max(
    0,
    Math.round((totalAmount - stockOffersAmount) / 2)
  )
  restOffers = sortByPrice(restOffers)
  const cheaperOffers: IOffer[] = restOffers.splice(0, cheaperOffersAmount)

  const fasterOffersAmount = Math.max(
    0,
    totalAmount - stockOffersAmount - cheaperOffersAmount
  )
  restOffers = sortByDeliveryTime(restOffers)
  const fasterOffers: IOffer[] = restOffers.splice(0, fasterOffersAmount)

  return [...stockOffers, ...cheaperOffers, ...fasterOffers]
}
