/* eslint-disable no-template-curly-in-string */
import { string, object, SchemaOf, ref } from 'yup'

import { ResetPasswordDTO } from '../dto'

export const resetPasswordSchema: SchemaOf<ResetPasswordDTO> = object({
  token: string().label('token').required('Токен восстановления не найден'),
  plainPassword: object({
    first: string().label('Пароль').min(6).required(),
    second: string()
      .label('Повторение пароля')
      .when({
        is: (value?: string) => Boolean(value),
        then: (schema) => schema.oneOf([ref('first')], 'Пароли не совпадают'),
      })
      .required(),
  }),
})
