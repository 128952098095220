import React from 'react'

import { TopSection } from './TopSection'
import { BottomSection } from './BottomSection'

type MobileHeaderProps = React.HTMLAttributes<HTMLElement> & {
  logoImage: string
}

// et-mobile~ et-clearfix~ transparent-false~ shadow-true~ shadow-sticky-true~ mobile-true~ tablet-portrait-true~ tablet-landscape-true~ desktop-false~
const MobileHeader: React.FC<MobileHeaderProps> = ({ logoImage, ...props }) => (
  <header {...props}>
    <BottomSection logoImage={logoImage} />
    <TopSection />
  </header>
)

export default MobileHeader
